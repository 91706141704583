const Oe = (e) => {
  const n = "=".repeat((4 - e.length % 4) % 4), t = (e + n).replace(/-/g, "+").replace(/_/g, "/"), r = window.atob(t), o = new Uint8Array(r.length);
  for (let i = 0; i < r.length; ++i)
    o[i] = r.charCodeAt(i);
  return o;
}, ae = ({ publicKey: e }) => ({ getSubscription: async () => {
  if (!("serviceWorker" in navigator) || !("PushManager" in window))
    throw {
      errorCode: "ServiceWorkerAndPushManagerNotSupported"
    };
  const t = await navigator.serviceWorker.ready;
  if (!t.pushManager)
    throw { errorCode: "PushManagerUnavailable" };
  const r = await t.pushManager.getSubscription();
  if (r)
    return r;
  const o = Oe(e);
  return await t.pushManager.subscribe({
    applicationServerKey: o,
    userVisibleOnly: !0
  });
} });
var fe = function() {
  return fe = Object.assign || function(n) {
    for (var t, r = 1, o = arguments.length; r < o; r++) {
      t = arguments[r];
      for (var i in t)
        Object.prototype.hasOwnProperty.call(t, i) && (n[i] = t[i]);
    }
    return n;
  }, fe.apply(this, arguments);
};
function M(e, n, t, r) {
  function o(i) {
    return i instanceof t ? i : new t(function(a) {
      a(i);
    });
  }
  return new (t || (t = Promise))(function(i, a) {
    function c(f) {
      try {
        l(r.next(f));
      } catch (u) {
        a(u);
      }
    }
    function s(f) {
      try {
        l(r.throw(f));
      } catch (u) {
        a(u);
      }
    }
    function l(f) {
      f.done ? i(f.value) : o(f.value).then(c, s);
    }
    l((r = r.apply(e, n || [])).next());
  });
}
function C(e, n) {
  var t = { label: 0, sent: function() {
    if (i[0] & 1)
      throw i[1];
    return i[1];
  }, trys: [], ops: [] }, r, o, i, a;
  return a = { next: c(0), throw: c(1), return: c(2) }, typeof Symbol == "function" && (a[Symbol.iterator] = function() {
    return this;
  }), a;
  function c(l) {
    return function(f) {
      return s([l, f]);
    };
  }
  function s(l) {
    if (r)
      throw new TypeError("Generator is already executing.");
    for (; a && (a = 0, l[0] && (t = 0)), t; )
      try {
        if (r = 1, o && (i = l[0] & 2 ? o.return : l[0] ? o.throw || ((i = o.return) && i.call(o), 0) : o.next) && !(i = i.call(o, l[1])).done)
          return i;
        switch (o = 0, i && (l = [l[0] & 2, i.value]), l[0]) {
          case 0:
          case 1:
            i = l;
            break;
          case 4:
            return t.label++, { value: l[1], done: !1 };
          case 5:
            t.label++, o = l[1], l = [0];
            continue;
          case 7:
            l = t.ops.pop(), t.trys.pop();
            continue;
          default:
            if (i = t.trys, !(i = i.length > 0 && i[i.length - 1]) && (l[0] === 6 || l[0] === 2)) {
              t = 0;
              continue;
            }
            if (l[0] === 3 && (!i || l[1] > i[0] && l[1] < i[3])) {
              t.label = l[1];
              break;
            }
            if (l[0] === 6 && t.label < i[1]) {
              t.label = i[1], i = l;
              break;
            }
            if (i && t.label < i[2]) {
              t.label = i[2], t.ops.push(l);
              break;
            }
            i[2] && t.ops.pop(), t.trys.pop();
            continue;
        }
        l = n.call(e, t);
      } catch (f) {
        l = [6, f], o = 0;
      } finally {
        r = i = 0;
      }
    if (l[0] & 5)
      throw l[1];
    return { value: l[0] ? l[1] : void 0, done: !0 };
  }
}
function Ae(e, n, t) {
  if (t || arguments.length === 2)
    for (var r = 0, o = n.length, i; r < o; r++)
      (i || !(r in n)) && (i || (i = Array.prototype.slice.call(n, 0, r)), i[r] = n[r]);
  return e.concat(i || Array.prototype.slice.call(n));
}
var Me = "4.2.1";
function O(e, n) {
  return new Promise(function(t) {
    return setTimeout(t, e, n);
  });
}
function te() {
  return O(0);
}
function Ue(e, n) {
  n === void 0 && (n = 1 / 0);
  var t = window.requestIdleCallback;
  return t ? new Promise(function(r) {
    return t.call(window, function() {
      return r();
    }, { timeout: n });
  }) : O(Math.min(e, n));
}
function Ce(e) {
  return !!e && typeof e.then == "function";
}
function ge(e, n) {
  try {
    var t = e();
    Ce(t) ? t.then(function(r) {
      return n(!0, r);
    }, function(r) {
      return n(!1, r);
    }) : n(!0, t);
  } catch (r) {
    n(!1, r);
  }
}
function be(e, n, t) {
  return t === void 0 && (t = 16), M(this, void 0, void 0, function() {
    var r, o, i, a;
    return C(this, function(c) {
      switch (c.label) {
        case 0:
          r = Array(e.length), o = Date.now(), i = 0, c.label = 1;
        case 1:
          return i < e.length ? (r[i] = n(e[i], i), a = Date.now(), a >= o + t ? (o = a, [4, O(0)]) : [3, 3]) : [3, 4];
        case 2:
          c.sent(), c.label = 3;
        case 3:
          return ++i, [3, 1];
        case 4:
          return [2, r];
      }
    });
  });
}
function re(e) {
  e.then(void 0, function() {
  });
}
function Ke(e, n) {
  for (var t = 0, r = e.length; t < r; ++t)
    if (e[t] === n)
      return !0;
  return !1;
}
function Qe(e, n) {
  return !Ke(e, n);
}
function me(e) {
  return parseInt(e);
}
function T(e) {
  return parseFloat(e);
}
function X(e, n) {
  return typeof e == "number" && isNaN(e) ? n : e;
}
function R(e) {
  return e.reduce(function(n, t) {
    return n + (t ? 1 : 0);
  }, 0);
}
function Fe(e, n) {
  if (n === void 0 && (n = 1), Math.abs(n) >= 1)
    return Math.round(e / n) * n;
  var t = 1 / n;
  return Math.round(e * t) / t;
}
function _e(e) {
  for (var n, t, r = "Unexpected syntax '".concat(e, "'"), o = /^\s*([a-z-]*)(.*)$/i.exec(e), i = o[1] || void 0, a = {}, c = /([.:#][\w-]+|\[.+?\])/gi, s = function(h, p) {
    a[h] = a[h] || [], a[h].push(p);
  }; ; ) {
    var l = c.exec(o[2]);
    if (!l)
      break;
    var f = l[0];
    switch (f[0]) {
      case ".":
        s("class", f.slice(1));
        break;
      case "#":
        s("id", f.slice(1));
        break;
      case "[": {
        var u = /^\[([\w-]+)([~|^$*]?=("(.*?)"|([\w-]+)))?(\s+[is])?\]$/.exec(f);
        if (u)
          s(u[1], (t = (n = u[4]) !== null && n !== void 0 ? n : u[5]) !== null && t !== void 0 ? t : "");
        else
          throw new Error(r);
        break;
      }
      default:
        throw new Error(r);
    }
  }
  return [i, a];
}
function qe(e) {
  for (var n = new Uint8Array(e.length), t = 0; t < e.length; t++) {
    var r = e.charCodeAt(t);
    if (r > 127)
      return new TextEncoder().encode(e);
    n[t] = r;
  }
  return n;
}
function B(e, n) {
  var t = e[0] >>> 16, r = e[0] & 65535, o = e[1] >>> 16, i = e[1] & 65535, a = n[0] >>> 16, c = n[0] & 65535, s = n[1] >>> 16, l = n[1] & 65535, f = 0, u = 0, h = 0, p = 0;
  p += i + l, h += p >>> 16, p &= 65535, h += o + s, u += h >>> 16, h &= 65535, u += r + c, f += u >>> 16, u &= 65535, f += t + a, f &= 65535, e[0] = f << 16 | u, e[1] = h << 16 | p;
}
function Z(e, n) {
  var t = e[0] >>> 16, r = e[0] & 65535, o = e[1] >>> 16, i = e[1] & 65535, a = n[0] >>> 16, c = n[0] & 65535, s = n[1] >>> 16, l = n[1] & 65535, f = 0, u = 0, h = 0, p = 0;
  p += i * l, h += p >>> 16, p &= 65535, h += o * l, u += h >>> 16, h &= 65535, h += i * s, u += h >>> 16, h &= 65535, u += r * l, f += u >>> 16, u &= 65535, u += o * s, f += u >>> 16, u &= 65535, u += i * c, f += u >>> 16, u &= 65535, f += t * l + r * s + o * c + i * a, f &= 65535, e[0] = f << 16 | u, e[1] = h << 16 | p;
}
function U(e, n) {
  var t = e[0];
  n %= 64, n === 32 ? (e[0] = e[1], e[1] = t) : n < 32 ? (e[0] = t << n | e[1] >>> 32 - n, e[1] = e[1] << n | t >>> 32 - n) : (n -= 32, e[0] = e[1] << n | t >>> 32 - n, e[1] = t << n | e[1] >>> 32 - n);
}
function D(e, n) {
  n %= 64, n !== 0 && (n < 32 ? (e[0] = e[1] >>> 32 - n, e[1] = e[1] << n) : (e[0] = e[1] << n - 32, e[1] = 0));
}
function x(e, n) {
  e[0] ^= n[0], e[1] ^= n[1];
}
var $e = [4283543511, 3981806797], en = [3301882366, 444984403];
function ye(e) {
  var n = [0, e[0] >>> 1];
  x(e, n), Z(e, $e), n[1] = e[0] >>> 1, x(e, n), Z(e, en), n[1] = e[0] >>> 1, x(e, n);
}
var q = [2277735313, 289559509], $ = [1291169091, 658871167], we = [0, 5], nn = [0, 1390208809], tn = [0, 944331445];
function rn(e, n) {
  var t = qe(e);
  n = n || 0;
  var r = [0, t.length], o = r[1] % 16, i = r[1] - o, a = [0, n], c = [0, n], s = [0, 0], l = [0, 0], f;
  for (f = 0; f < i; f = f + 16)
    s[0] = t[f + 4] | t[f + 5] << 8 | t[f + 6] << 16 | t[f + 7] << 24, s[1] = t[f] | t[f + 1] << 8 | t[f + 2] << 16 | t[f + 3] << 24, l[0] = t[f + 12] | t[f + 13] << 8 | t[f + 14] << 16 | t[f + 15] << 24, l[1] = t[f + 8] | t[f + 9] << 8 | t[f + 10] << 16 | t[f + 11] << 24, Z(s, q), U(s, 31), Z(s, $), x(a, s), U(a, 27), B(a, c), Z(a, we), B(a, nn), Z(l, $), U(l, 33), Z(l, q), x(c, l), U(c, 31), B(c, a), Z(c, we), B(c, tn);
  s[0] = 0, s[1] = 0, l[0] = 0, l[1] = 0;
  var u = [0, 0];
  switch (o) {
    case 15:
      u[1] = t[f + 14], D(u, 48), x(l, u);
    case 14:
      u[1] = t[f + 13], D(u, 40), x(l, u);
    case 13:
      u[1] = t[f + 12], D(u, 32), x(l, u);
    case 12:
      u[1] = t[f + 11], D(u, 24), x(l, u);
    case 11:
      u[1] = t[f + 10], D(u, 16), x(l, u);
    case 10:
      u[1] = t[f + 9], D(u, 8), x(l, u);
    case 9:
      u[1] = t[f + 8], x(l, u), Z(l, $), U(l, 33), Z(l, q), x(c, l);
    case 8:
      u[1] = t[f + 7], D(u, 56), x(s, u);
    case 7:
      u[1] = t[f + 6], D(u, 48), x(s, u);
    case 6:
      u[1] = t[f + 5], D(u, 40), x(s, u);
    case 5:
      u[1] = t[f + 4], D(u, 32), x(s, u);
    case 4:
      u[1] = t[f + 3], D(u, 24), x(s, u);
    case 3:
      u[1] = t[f + 2], D(u, 16), x(s, u);
    case 2:
      u[1] = t[f + 1], D(u, 8), x(s, u);
    case 1:
      u[1] = t[f], x(s, u), Z(s, q), U(s, 31), Z(s, $), x(a, s);
  }
  return x(a, r), x(c, r), B(a, c), B(c, a), ye(a), ye(c), B(a, c), B(c, a), ("00000000" + (a[0] >>> 0).toString(16)).slice(-8) + ("00000000" + (a[1] >>> 0).toString(16)).slice(-8) + ("00000000" + (c[0] >>> 0).toString(16)).slice(-8) + ("00000000" + (c[1] >>> 0).toString(16)).slice(-8);
}
function on(e) {
  var n;
  return fe({ name: e.name, message: e.message, stack: (n = e.stack) === null || n === void 0 ? void 0 : n.split(`
`) }, e);
}
function an(e) {
  return /^function\s.*?\{\s*\[native code]\s*}$/.test(String(e));
}
function cn(e) {
  return typeof e != "function";
}
function sn(e, n) {
  var t = new Promise(function(r) {
    var o = Date.now();
    ge(e.bind(null, n), function() {
      for (var i = [], a = 0; a < arguments.length; a++)
        i[a] = arguments[a];
      var c = Date.now() - o;
      if (!i[0])
        return r(function() {
          return { error: i[1], duration: c };
        });
      var s = i[1];
      if (cn(s))
        return r(function() {
          return { value: s, duration: c };
        });
      r(function() {
        return new Promise(function(l) {
          var f = Date.now();
          ge(s, function() {
            for (var u = [], h = 0; h < arguments.length; h++)
              u[h] = arguments[h];
            var p = c + Date.now() - f;
            if (!u[0])
              return l({ error: u[1], duration: p });
            l({ value: u[1], duration: p });
          });
        });
      });
    });
  });
  return re(t), function() {
    return t.then(function(o) {
      return o();
    });
  };
}
function un(e, n, t) {
  var r = Object.keys(e).filter(function(i) {
    return Qe(t, i);
  }), o = be(r, function(i) {
    return sn(e[i], n);
  });
  return re(o), function() {
    return M(this, void 0, void 0, function() {
      var a, c, s, l, f;
      return C(this, function(u) {
        switch (u.label) {
          case 0:
            return [4, o];
          case 1:
            return a = u.sent(), [4, be(a, function(h) {
              var p = h();
              return re(p), p;
            })];
          case 2:
            return c = u.sent(), [
              4,
              Promise.all(c)
            ];
          case 3:
            for (s = u.sent(), l = {}, f = 0; f < r.length; ++f)
              l[r[f]] = s[f];
            return [2, l];
        }
      });
    });
  };
}
function We() {
  var e = window, n = navigator;
  return R([
    "MSCSSMatrix" in e,
    "msSetImmediate" in e,
    "msIndexedDB" in e,
    "msMaxTouchPoints" in n,
    "msPointerEnabled" in n
  ]) >= 4;
}
function ln() {
  var e = window, n = navigator;
  return R(["msWriteProfilerMark" in e, "MSStream" in e, "msLaunchUri" in n, "msSaveBlob" in n]) >= 3 && !We();
}
function he() {
  var e = window, n = navigator;
  return R([
    "webkitPersistentStorage" in n,
    "webkitTemporaryStorage" in n,
    n.vendor.indexOf("Google") === 0,
    "webkitResolveLocalFileSystemURL" in e,
    "BatteryManager" in e,
    "webkitMediaStream" in e,
    "webkitSpeechGrammar" in e
  ]) >= 5;
}
function z() {
  var e = window, n = navigator;
  return R([
    "ApplePayError" in e,
    "CSSPrimitiveValue" in e,
    "Counter" in e,
    n.vendor.indexOf("Apple") === 0,
    "getStorageUpdates" in n,
    "WebKitMediaKeys" in e
  ]) >= 4;
}
function pe() {
  var e = window, n = e.HTMLElement, t = e.Document;
  return R([
    "safari" in e,
    !("ongestureend" in e),
    !("TouchEvent" in e),
    !("orientation" in e),
    n && !("autocapitalize" in n.prototype),
    t && "pointerLockElement" in t.prototype
  ]) >= 4;
}
function ie() {
  var e = window;
  return an(e.print) ? R([
    String(e.browser) === "[object WebPageNamespace]",
    "MicrodataExtractor" in e
  ]) >= 1 : !1;
}
function Re() {
  var e, n, t = window;
  return R([
    "buildID" in navigator,
    "MozAppearance" in ((n = (e = document.documentElement) === null || e === void 0 ? void 0 : e.style) !== null && n !== void 0 ? n : {}),
    "onmozfullscreenchange" in t,
    "mozInnerScreenX" in t,
    "CSSMozDocumentRule" in t,
    "CanvasCaptureMediaStream" in t
  ]) >= 4;
}
function fn() {
  var e = window;
  return R([
    !("MediaSettingsRange" in e),
    "RTCEncodedAudioFrame" in e,
    "" + e.Intl == "[object Intl]",
    "" + e.Reflect == "[object Reflect]"
  ]) >= 3;
}
function dn() {
  var e = window;
  return R([
    "DOMRectList" in e,
    "RTCPeerConnectionIceEvent" in e,
    "SVGGeometryElement" in e,
    "ontransitioncancel" in e
  ]) >= 3;
}
function oe() {
  var e = window, n = navigator, t = e.CSS, r = e.HTMLButtonElement;
  return R([
    !("getStorageUpdates" in n),
    r && "popover" in r.prototype,
    "CSSCounterStyleRule" in e,
    t.supports("font-size-adjust: ex-height 0.5"),
    t.supports("text-transform: full-width")
  ]) >= 4;
}
function vn() {
  if (navigator.platform === "iPad")
    return !0;
  var e = screen, n = e.width / e.height;
  return R([
    "MediaSource" in window,
    !!Element.prototype.webkitRequestFullscreen,
    n > 0.65 && n < 1.53
  ]) >= 2;
}
function mn() {
  var e = document;
  return e.fullscreenElement || e.msFullscreenElement || e.mozFullScreenElement || e.webkitFullscreenElement || null;
}
function hn() {
  var e = document;
  return (e.exitFullscreen || e.msExitFullscreen || e.mozCancelFullScreen || e.webkitExitFullscreen).call(e);
}
function De() {
  var e = he(), n = Re(), t = window, r = navigator, o = "connection";
  return e ? R([
    !("SharedWorker" in t),
    r[o] && "ontypechange" in r[o],
    !("sinkId" in new window.Audio())
  ]) >= 2 : n ? R(["onorientationchange" in t, "orientation" in t, /android/i.test(navigator.appVersion)]) >= 2 : !1;
}
function Ze(e, n, t) {
  var r, o, i;
  return t === void 0 && (t = 50), M(this, void 0, void 0, function() {
    var a, c;
    return C(this, function(s) {
      switch (s.label) {
        case 0:
          a = document, s.label = 1;
        case 1:
          return a.body ? [3, 3] : [4, O(t)];
        case 2:
          return s.sent(), [3, 1];
        case 3:
          c = a.createElement("iframe"), s.label = 4;
        case 4:
          return s.trys.push([4, , 10, 11]), [4, new Promise(function(l, f) {
            var u = !1, h = function() {
              u = !0, l();
            }, p = function(V) {
              u = !0, f(V);
            };
            c.onload = h, c.onerror = p;
            var k = c.style;
            k.setProperty("display", "block", "important"), k.position = "absolute", k.top = "0", k.left = "0", k.visibility = "hidden", n && "srcdoc" in c ? c.srcdoc = n : c.src = "about:blank", a.body.appendChild(c);
            var P = function() {
              var V, S;
              u || (((S = (V = c.contentWindow) === null || V === void 0 ? void 0 : V.document) === null || S === void 0 ? void 0 : S.readyState) === "complete" ? h() : setTimeout(P, 10));
            };
            P();
          })];
        case 5:
          s.sent(), s.label = 6;
        case 6:
          return !((o = (r = c.contentWindow) === null || r === void 0 ? void 0 : r.document) === null || o === void 0) && o.body ? [3, 8] : [4, O(t)];
        case 7:
          return s.sent(), [3, 6];
        case 8:
          return [4, e(c, c.contentWindow)];
        case 9:
          return [2, s.sent()];
        case 10:
          return (i = c.parentNode) === null || i === void 0 || i.removeChild(c), [7];
        case 11:
          return [2];
      }
    });
  });
}
function pn(e) {
  for (var n = _e(e), t = n[0], r = n[1], o = document.createElement(t ?? "div"), i = 0, a = Object.keys(r); i < a.length; i++) {
    var c = a[i], s = r[c].join(" ");
    c === "style" ? gn(o.style, s) : o.setAttribute(c, s);
  }
  return o;
}
function gn(e, n) {
  for (var t = 0, r = n.split(";"); t < r.length; t++) {
    var o = r[t], i = /^\s*([\w-]+)\s*:\s*(.+?)(\s*!([\w-]+))?\s*$/.exec(o);
    if (i) {
      var a = i[1], c = i[2], s = i[4];
      e.setProperty(a, c, s || "");
    }
  }
}
function bn() {
  return new Promise(function(e) {
    var n = document, t = "visibilitychange", r = function() {
      n.hidden || (n.removeEventListener(t, r), e());
    };
    n.addEventListener(t, r), r();
  });
}
var ee = 44100, yn = 4e4, wn = 6.2;
function Sn() {
  return M(this, void 0, void 0, function() {
    var e;
    return C(this, function(n) {
      switch (n.label) {
        case 0:
          return [4, kn()];
        case 1:
          return e = n.sent(), [2, function() {
            var t = e();
            return En(t, wn);
          }];
      }
    });
  });
}
function kn() {
  return M(this, void 0, void 0, function() {
    var e, n, t;
    return C(this, function(r) {
      switch (r.label) {
        case 0:
          return n = bn().then(function() {
            return O(500);
          }), t = Ln().then(function(o) {
            return e = [!0, o];
          }, function(o) {
            return e = [!1, o];
          }), [4, Promise.race([n, t])];
        case 1:
          return r.sent(), [2, function() {
            if (!e)
              return -3;
            if (!e[0])
              throw e[1];
            return e[1];
          }];
      }
    });
  });
}
function Ln() {
  return M(this, void 0, void 0, function() {
    var e, n, t, r, o, i, a;
    return C(this, function(c) {
      switch (c.label) {
        case 0:
          return e = window, n = e.OfflineAudioContext || e.webkitOfflineAudioContext, n ? In() ? [2, -1] : [4, xn(n)] : [2, -2];
        case 1:
          return t = c.sent(), t ? (r = new n(1, t.length - 1 + yn, ee), o = r.createBufferSource(), o.buffer = t, o.loop = !0, o.loopStart = (t.length - 1) / ee, o.loopEnd = t.length / ee, o.connect(r.destination), o.start(), [4, Ge(r)]) : [2, -3];
        case 2:
          return i = c.sent(), i ? (a = Pn(t, i.getChannelData(0).subarray(t.length - 1)), [2, Math.abs(a)]) : [2, -3];
      }
    });
  });
}
function In() {
  return z() && !pe() && !dn();
}
function xn(e) {
  return M(this, void 0, void 0, function() {
    var n, t, r, o, i;
    return C(this, function(a) {
      switch (a.label) {
        case 0:
          return n = 3395, t = new e(1, n + 1, ee), r = t.createOscillator(), r.type = "square", r.frequency.value = 1e3, o = t.createDynamicsCompressor(), o.threshold.value = -70, o.knee.value = 40, o.ratio.value = 12, o.attack.value = 0, o.release.value = 0.25, i = t.createBiquadFilter(), i.type = "allpass", i.frequency.value = 5.239622852977861, i.Q.value = 0.1, r.connect(o), o.connect(i), i.connect(t.destination), r.start(0), [4, Ge(t)];
        case 1:
          return [2, a.sent()];
      }
    });
  });
}
function Ge(e) {
  return new Promise(function(n, t) {
    var r = 200, o = 25;
    e.oncomplete = function(a) {
      return n(a.renderedBuffer);
    };
    var i = function() {
      try {
        var a = e.startRendering();
        Ce(a) && re(a), e.state === "suspended" && (document.hidden || o--, o > 0 ? setTimeout(i, r) : n(null));
      } catch (c) {
        t(c);
      }
    };
    i();
  });
}
function Pn(e, n) {
  for (var t = void 0, r = !1, o = 0; o < n.length; o += Math.floor(n.length / 10))
    if (n[o] !== 0) {
      if (t === void 0)
        t = n[o];
      else if (t !== n[o]) {
        r = !0;
        break;
      }
    }
  return t === void 0 ? t = e.getChannelData(0)[e.length - 1] : r && (t = Vn(n)), t;
}
function Vn(e) {
  for (var n = 1 / 0, t = -1 / 0, r = 0; r < e.length; r++) {
    var o = e[r];
    o !== 0 && (o < n && (n = o), o > t && (t = o));
  }
  return (n + t) / 2;
}
function En(e, n) {
  if (e === 0)
    return e;
  var t = Math.floor(Math.log10(Math.abs(e))), r = t - Math.floor(n) + 1, o = Math.pow(10, -r) * (n * 10 % 10 || 1);
  return Math.round(e * o) / o;
}
var An = "mmMwWLliI0O&1", Mn = "48px", K = ["monospace", "sans-serif", "serif"], Se = [
  "sans-serif-thin",
  "ARNO PRO",
  "Agency FB",
  "Arabic Typesetting",
  "Arial Unicode MS",
  "AvantGarde Bk BT",
  "BankGothic Md BT",
  "Batang",
  "Bitstream Vera Sans Mono",
  "Calibri",
  "Century",
  "Century Gothic",
  "Clarendon",
  "EUROSTILE",
  "Franklin Gothic",
  "Futura Bk BT",
  "Futura Md BT",
  "GOTHAM",
  "Gill Sans",
  "HELV",
  "Haettenschweiler",
  "Helvetica Neue",
  "Humanst521 BT",
  "Leelawadee",
  "Letter Gothic",
  "Levenim MT",
  "Lucida Bright",
  "Lucida Sans",
  "Menlo",
  "MS Mincho",
  "MS Outlook",
  "MS Reference Specialty",
  "MS UI Gothic",
  "MT Extra",
  "MYRIAD PRO",
  "Marlett",
  "Meiryo UI",
  "Microsoft Uighur",
  "Minion Pro",
  "Monotype Corsiva",
  "PMingLiU",
  "Pristina",
  "SCRIPTINA",
  "Segoe UI Light",
  "Serifa",
  "SimHei",
  "Small Fonts",
  "Staccato222 BT",
  "TRAJAN PRO",
  "Univers CE 55 Medium",
  "Vrinda",
  "ZWAdobeF"
];
function Cn() {
  var e = this;
  return Ze(function(n, t) {
    var r = t.document;
    return M(e, void 0, void 0, function() {
      var o, i, a, c, s, l, f, u, h, p, k, P;
      return C(this, function(V) {
        switch (V.label) {
          case 0:
            return o = r.body, o.style.fontSize = Mn, i = r.createElement("div"), i.style.setProperty("visibility", "hidden", "important"), a = {}, c = {}, s = function(S) {
              var y = r.createElement("span"), E = y.style;
              return E.position = "absolute", E.top = "0", E.left = "0", E.fontFamily = S, y.textContent = An, i.appendChild(y), y;
            }, l = function(S, y) {
              return s("'".concat(S, "',").concat(y));
            }, f = function() {
              return K.map(s);
            }, u = function() {
              for (var S = {}, y = function(N) {
                S[N] = K.map(function(A) {
                  return l(N, A);
                });
              }, E = 0, G = Se; E < G.length; E++) {
                var j = G[E];
                y(j);
              }
              return S;
            }, h = function(S) {
              return K.some(function(y, E) {
                return S[E].offsetWidth !== a[y] || S[E].offsetHeight !== c[y];
              });
            }, p = f(), k = u(), o.appendChild(i), [
              4,
              te()
            ];
          case 1:
            for (V.sent(), P = 0; P < K.length; P++)
              a[K[P]] = p[P].offsetWidth, c[K[P]] = p[P].offsetHeight;
            return [2, Se.filter(function(S) {
              return h(k[S]);
            })];
        }
      });
    });
  });
}
function Fn() {
  var e = navigator.plugins;
  if (!!e) {
    for (var n = [], t = 0; t < e.length; ++t) {
      var r = e[t];
      if (!!r) {
        for (var o = [], i = 0; i < r.length; ++i) {
          var a = r[i];
          o.push({
            type: a.type,
            suffixes: a.suffixes
          });
        }
        n.push({
          name: r.name,
          description: r.description,
          mimeTypes: o
        });
      }
    }
    return n;
  }
}
function Wn() {
  return Rn(Yn());
}
function Rn(e) {
  return M(this, void 0, void 0, function() {
    var n, t, r, o, i, a, c;
    return C(this, function(s) {
      switch (s.label) {
        case 0:
          return n = !1, o = Dn(), i = o[0], a = o[1], Zn(i, a) ? [3, 1] : (t = r = "unsupported", [3, 4]);
        case 1:
          return n = Gn(a), e ? (t = r = "skipped", [3, 4]) : [3, 2];
        case 2:
          return [4, Tn(i, a)];
        case 3:
          c = s.sent(), t = c[0], r = c[1], s.label = 4;
        case 4:
          return [2, { winding: n, geometry: t, text: r }];
      }
    });
  });
}
function Dn() {
  var e = document.createElement("canvas");
  return e.width = 1, e.height = 1, [e, e.getContext("2d")];
}
function Zn(e, n) {
  return !!(n && e.toDataURL);
}
function Gn(e) {
  return e.rect(0, 0, 10, 10), e.rect(2, 2, 6, 6), !e.isPointInPath(5, 5, "evenodd");
}
function Tn(e, n) {
  return M(this, void 0, void 0, function() {
    var t, r, o;
    return C(this, function(i) {
      switch (i.label) {
        case 0:
          return jn(e, n), [4, te()];
        case 1:
          return i.sent(), t = ce(e), r = ce(e), t !== r ? [2, ["unstable", "unstable"]] : (Nn(e, n), [4, te()]);
        case 2:
          return i.sent(), o = ce(e), [2, [t, o]];
      }
    });
  });
}
function jn(e, n) {
  e.width = 240, e.height = 60, n.textBaseline = "alphabetic", n.fillStyle = "#f60", n.fillRect(100, 1, 62, 20), n.fillStyle = "#069", n.font = '11pt "Times New Roman"';
  var t = "Cwm fjordbank gly ".concat(String.fromCharCode(55357, 56835));
  n.fillText(t, 2, 15), n.fillStyle = "rgba(102, 204, 0, 0.2)", n.font = "18pt Arial", n.fillText(t, 4, 45);
}
function Nn(e, n) {
  e.width = 122, e.height = 110, n.globalCompositeOperation = "multiply";
  for (var t = 0, r = [
    ["#f2f", 40, 40],
    ["#2ff", 80, 40],
    ["#ff2", 60, 80]
  ]; t < r.length; t++) {
    var o = r[t], i = o[0], a = o[1], c = o[2];
    n.fillStyle = i, n.beginPath(), n.arc(a, c, 40, 0, Math.PI * 2, !0), n.closePath(), n.fill();
  }
  n.fillStyle = "#f9c", n.arc(60, 60, 60, 0, Math.PI * 2, !0), n.arc(60, 60, 20, 0, Math.PI * 2, !0), n.fill("evenodd");
}
function ce(e) {
  return e.toDataURL();
}
function Yn() {
  return z() && oe() && ie();
}
function Xn() {
  var e = navigator, n = 0, t;
  e.maxTouchPoints !== void 0 ? n = me(e.maxTouchPoints) : e.msMaxTouchPoints !== void 0 && (n = e.msMaxTouchPoints);
  try {
    document.createEvent("TouchEvent"), t = !0;
  } catch {
    t = !1;
  }
  var r = "ontouchstart" in window;
  return {
    maxTouchPoints: n,
    touchEvent: t,
    touchStart: r
  };
}
function Hn() {
  return navigator.oscpu;
}
function Bn() {
  var e = navigator, n = [], t = e.language || e.userLanguage || e.browserLanguage || e.systemLanguage;
  if (t !== void 0 && n.push([t]), Array.isArray(e.languages))
    he() && fn() || n.push(e.languages);
  else if (typeof e.languages == "string") {
    var r = e.languages;
    r && n.push(r.split(","));
  }
  return n;
}
function zn() {
  return window.screen.colorDepth;
}
function Jn() {
  return X(T(navigator.deviceMemory), void 0);
}
function On() {
  if (!(z() && oe() && ie()))
    return Un();
}
function Un() {
  var e = screen, n = function(r) {
    return X(me(r), null);
  }, t = [n(e.width), n(e.height)];
  return t.sort().reverse(), t;
}
var Kn = 2500, Qn = 10, ne, se;
function _n() {
  if (se === void 0) {
    var e = function() {
      var n = de();
      ve(n) ? se = setTimeout(e, Kn) : (ne = n, se = void 0);
    };
    e();
  }
}
function qn() {
  var e = this;
  return _n(), function() {
    return M(e, void 0, void 0, function() {
      var n;
      return C(this, function(t) {
        switch (t.label) {
          case 0:
            return n = de(), ve(n) ? ne ? [2, Ae([], ne, !0)] : mn() ? [4, hn()] : [3, 2] : [3, 2];
          case 1:
            t.sent(), n = de(), t.label = 2;
          case 2:
            return ve(n) || (ne = n), [2, n];
        }
      });
    });
  };
}
function $n() {
  var e = this;
  if (z() && oe() && ie())
    return function() {
      return Promise.resolve(void 0);
    };
  var n = qn();
  return function() {
    return M(e, void 0, void 0, function() {
      var t, r;
      return C(this, function(o) {
        switch (o.label) {
          case 0:
            return [4, n()];
          case 1:
            return t = o.sent(), r = function(i) {
              return i === null ? null : Fe(i, Qn);
            }, [2, [r(t[0]), r(t[1]), r(t[2]), r(t[3])]];
        }
      });
    });
  };
}
function de() {
  var e = screen;
  return [
    X(T(e.availTop), null),
    X(T(e.width) - T(e.availWidth) - X(T(e.availLeft), 0), null),
    X(T(e.height) - T(e.availHeight) - X(T(e.availTop), 0), null),
    X(T(e.availLeft), null)
  ];
}
function ve(e) {
  for (var n = 0; n < 4; ++n)
    if (e[n])
      return !1;
  return !0;
}
function et() {
  return X(me(navigator.hardwareConcurrency), void 0);
}
function nt() {
  var e, n = (e = window.Intl) === null || e === void 0 ? void 0 : e.DateTimeFormat;
  if (n) {
    var t = new n().resolvedOptions().timeZone;
    if (t)
      return t;
  }
  var r = -tt();
  return "UTC".concat(r >= 0 ? "+" : "").concat(Math.abs(r));
}
function tt() {
  var e = new Date().getFullYear();
  return Math.max(
    T(new Date(e, 0, 1).getTimezoneOffset()),
    T(new Date(e, 6, 1).getTimezoneOffset())
  );
}
function rt() {
  try {
    return !!window.sessionStorage;
  } catch {
    return !0;
  }
}
function it() {
  try {
    return !!window.localStorage;
  } catch {
    return !0;
  }
}
function ot() {
  if (!(We() || ln()))
    try {
      return !!window.indexedDB;
    } catch {
      return !0;
    }
}
function at() {
  return !!window.openDatabase;
}
function ct() {
  return navigator.cpuClass;
}
function st() {
  var e = navigator.platform;
  return e === "MacIntel" && z() && !pe() ? vn() ? "iPad" : "iPhone" : e;
}
function ut() {
  return navigator.vendor || "";
}
function lt() {
  for (var e = [], n = 0, t = [
    "chrome",
    "safari",
    "__crWeb",
    "__gCrWeb",
    "yandex",
    "__yb",
    "__ybro",
    "__firefox__",
    "__edgeTrackingPreventionStatistics",
    "webkit",
    "oprt",
    "samsungAr",
    "ucweb",
    "UCShellJava",
    "puffinDevice"
  ]; n < t.length; n++) {
    var r = t[n], o = window[r];
    o && typeof o == "object" && e.push(r);
  }
  return e.sort();
}
function ft() {
  var e = document;
  try {
    e.cookie = "cookietest=1; SameSite=Strict;";
    var n = e.cookie.indexOf("cookietest=") !== -1;
    return e.cookie = "cookietest=1; SameSite=Strict; expires=Thu, 01-Jan-1970 00:00:01 GMT", n;
  } catch {
    return !1;
  }
}
function dt() {
  var e = atob;
  return {
    abpIndo: [
      "#Iklan-Melayang",
      "#Kolom-Iklan-728",
      "#SidebarIklan-wrapper",
      '[title="ALIENBOLA" i]',
      e("I0JveC1CYW5uZXItYWRz")
    ],
    abpvn: [".quangcao", "#mobileCatfish", e("LmNsb3NlLWFkcw=="), '[id^="bn_bottom_fixed_"]', "#pmadv"],
    adBlockFinland: [
      ".mainostila",
      e("LnNwb25zb3JpdA=="),
      ".ylamainos",
      e("YVtocmVmKj0iL2NsaWNrdGhyZ2guYXNwPyJd"),
      e("YVtocmVmXj0iaHR0cHM6Ly9hcHAucmVhZHBlYWsuY29tL2FkcyJd")
    ],
    adBlockPersian: [
      "#navbar_notice_50",
      ".kadr",
      'TABLE[width="140px"]',
      "#divAgahi",
      e("YVtocmVmXj0iaHR0cDovL2cxLnYuZndtcm0ubmV0L2FkLyJd")
    ],
    adBlockWarningRemoval: [
      "#adblock-honeypot",
      ".adblocker-root",
      ".wp_adblock_detect",
      e("LmhlYWRlci1ibG9ja2VkLWFk"),
      e("I2FkX2Jsb2NrZXI=")
    ],
    adGuardAnnoyances: [
      ".hs-sosyal",
      "#cookieconsentdiv",
      'div[class^="app_gdpr"]',
      ".as-oil",
      '[data-cypress="soft-push-notification-modal"]'
    ],
    adGuardBase: [
      ".BetterJsPopOverlay",
      e("I2FkXzMwMFgyNTA="),
      e("I2Jhbm5lcmZsb2F0MjI="),
      e("I2NhbXBhaWduLWJhbm5lcg=="),
      e("I0FkLUNvbnRlbnQ=")
    ],
    adGuardChinese: [
      e("LlppX2FkX2FfSA=="),
      e("YVtocmVmKj0iLmh0aGJldDM0LmNvbSJd"),
      "#widget-quan",
      e("YVtocmVmKj0iLzg0OTkyMDIwLnh5eiJd"),
      e("YVtocmVmKj0iLjE5NTZobC5jb20vIl0=")
    ],
    adGuardFrench: [
      "#pavePub",
      e("LmFkLWRlc2t0b3AtcmVjdGFuZ2xl"),
      ".mobile_adhesion",
      ".widgetadv",
      e("LmFkc19iYW4=")
    ],
    adGuardGerman: ['aside[data-portal-id="leaderboard"]'],
    adGuardJapanese: [
      "#kauli_yad_1",
      e("YVtocmVmXj0iaHR0cDovL2FkMi50cmFmZmljZ2F0ZS5uZXQvIl0="),
      e("Ll9wb3BJbl9pbmZpbml0ZV9hZA=="),
      e("LmFkZ29vZ2xl"),
      e("Ll9faXNib29zdFJldHVybkFk")
    ],
    adGuardMobile: [
      e("YW1wLWF1dG8tYWRz"),
      e("LmFtcF9hZA=="),
      'amp-embed[type="24smi"]',
      "#mgid_iframe1",
      e("I2FkX2ludmlld19hcmVh")
    ],
    adGuardRussian: [
      e("YVtocmVmXj0iaHR0cHM6Ly9hZC5sZXRtZWFkcy5jb20vIl0="),
      e("LnJlY2xhbWE="),
      'div[id^="smi2adblock"]',
      e("ZGl2W2lkXj0iQWRGb3hfYmFubmVyXyJd"),
      "#psyduckpockeball"
    ],
    adGuardSocial: [
      e("YVtocmVmXj0iLy93d3cuc3R1bWJsZXVwb24uY29tL3N1Ym1pdD91cmw9Il0="),
      e("YVtocmVmXj0iLy90ZWxlZ3JhbS5tZS9zaGFyZS91cmw/Il0="),
      ".etsy-tweet",
      "#inlineShare",
      ".popup-social"
    ],
    adGuardSpanishPortuguese: ["#barraPublicidade", "#Publicidade", "#publiEspecial", "#queTooltip", ".cnt-publi"],
    adGuardTrackingProtection: [
      "#qoo-counter",
      e("YVtocmVmXj0iaHR0cDovL2NsaWNrLmhvdGxvZy5ydS8iXQ=="),
      e("YVtocmVmXj0iaHR0cDovL2hpdGNvdW50ZXIucnUvdG9wL3N0YXQucGhwIl0="),
      e("YVtocmVmXj0iaHR0cDovL3RvcC5tYWlsLnJ1L2p1bXAiXQ=="),
      "#top100counter"
    ],
    adGuardTurkish: [
      "#backkapat",
      e("I3Jla2xhbWk="),
      e("YVtocmVmXj0iaHR0cDovL2Fkc2Vydi5vbnRlay5jb20udHIvIl0="),
      e("YVtocmVmXj0iaHR0cDovL2l6bGVuemkuY29tL2NhbXBhaWduLyJd"),
      e("YVtocmVmXj0iaHR0cDovL3d3dy5pbnN0YWxsYWRzLm5ldC8iXQ==")
    ],
    bulgarian: [e("dGQjZnJlZW5ldF90YWJsZV9hZHM="), "#ea_intext_div", ".lapni-pop-over", "#xenium_hot_offers"],
    easyList: [
      ".yb-floorad",
      e("LndpZGdldF9wb19hZHNfd2lkZ2V0"),
      e("LnRyYWZmaWNqdW5reS1hZA=="),
      ".textad_headline",
      e("LnNwb25zb3JlZC10ZXh0LWxpbmtz")
    ],
    easyListChina: [
      e("LmFwcGd1aWRlLXdyYXBbb25jbGljayo9ImJjZWJvcy5jb20iXQ=="),
      e("LmZyb250cGFnZUFkdk0="),
      "#taotaole",
      "#aafoot.top_box",
      ".cfa_popup"
    ],
    easyListCookie: [
      ".ezmob-footer",
      ".cc-CookieWarning",
      "[data-cookie-number]",
      e("LmF3LWNvb2tpZS1iYW5uZXI="),
      ".sygnal24-gdpr-modal-wrap"
    ],
    easyListCzechSlovak: [
      "#onlajny-stickers",
      e("I3Jla2xhbW5pLWJveA=="),
      e("LnJla2xhbWEtbWVnYWJvYXJk"),
      ".sklik",
      e("W2lkXj0ic2tsaWtSZWtsYW1hIl0=")
    ],
    easyListDutch: [
      e("I2FkdmVydGVudGll"),
      e("I3ZpcEFkbWFya3RCYW5uZXJCbG9jaw=="),
      ".adstekst",
      e("YVtocmVmXj0iaHR0cHM6Ly94bHR1YmUubmwvY2xpY2svIl0="),
      "#semilo-lrectangle"
    ],
    easyListGermany: [
      "#SSpotIMPopSlider",
      e("LnNwb25zb3JsaW5rZ3J1ZW4="),
      e("I3dlcmJ1bmdza3k="),
      e("I3Jla2xhbWUtcmVjaHRzLW1pdHRl"),
      e("YVtocmVmXj0iaHR0cHM6Ly9iZDc0Mi5jb20vIl0=")
    ],
    easyListItaly: [
      e("LmJveF9hZHZfYW5udW5jaQ=="),
      ".sb-box-pubbliredazionale",
      e("YVtocmVmXj0iaHR0cDovL2FmZmlsaWF6aW9uaWFkcy5zbmFpLml0LyJd"),
      e("YVtocmVmXj0iaHR0cHM6Ly9hZHNlcnZlci5odG1sLml0LyJd"),
      e("YVtocmVmXj0iaHR0cHM6Ly9hZmZpbGlhemlvbmlhZHMuc25haS5pdC8iXQ==")
    ],
    easyListLithuania: [
      e("LnJla2xhbW9zX3RhcnBhcw=="),
      e("LnJla2xhbW9zX251b3JvZG9z"),
      e("aW1nW2FsdD0iUmVrbGFtaW5pcyBza3lkZWxpcyJd"),
      e("aW1nW2FsdD0iRGVkaWt1b3RpLmx0IHNlcnZlcmlhaSJd"),
      e("aW1nW2FsdD0iSG9zdGluZ2FzIFNlcnZlcmlhaS5sdCJd")
    ],
    estonian: [e("QVtocmVmKj0iaHR0cDovL3BheTRyZXN1bHRzMjQuZXUiXQ==")],
    fanboyAnnoyances: ["#ac-lre-player", ".navigate-to-top", "#subscribe_popup", ".newsletter_holder", "#back-top"],
    fanboyAntiFacebook: [".util-bar-module-firefly-visible"],
    fanboyEnhancedTrackers: [
      ".open.pushModal",
      "#issuem-leaky-paywall-articles-zero-remaining-nag",
      "#sovrn_container",
      'div[class$="-hide"][zoompage-fontsize][style="display: block;"]',
      ".BlockNag__Card"
    ],
    fanboySocial: ["#FollowUs", "#meteored_share", "#social_follow", ".article-sharer", ".community__social-desc"],
    frellwitSwedish: [
      e("YVtocmVmKj0iY2FzaW5vcHJvLnNlIl1bdGFyZ2V0PSJfYmxhbmsiXQ=="),
      e("YVtocmVmKj0iZG9rdG9yLXNlLm9uZWxpbmsubWUiXQ=="),
      "article.category-samarbete",
      e("ZGl2LmhvbGlkQWRz"),
      "ul.adsmodern"
    ],
    greekAdBlock: [
      e("QVtocmVmKj0iYWRtYW4ub3RlbmV0LmdyL2NsaWNrPyJd"),
      e("QVtocmVmKj0iaHR0cDovL2F4aWFiYW5uZXJzLmV4b2R1cy5nci8iXQ=="),
      e("QVtocmVmKj0iaHR0cDovL2ludGVyYWN0aXZlLmZvcnRobmV0LmdyL2NsaWNrPyJd"),
      "DIV.agores300",
      "TABLE.advright"
    ],
    hungarian: [
      "#cemp_doboz",
      ".optimonk-iframe-container",
      e("LmFkX19tYWlu"),
      e("W2NsYXNzKj0iR29vZ2xlQWRzIl0="),
      "#hirdetesek_box"
    ],
    iDontCareAboutCookies: [
      '.alert-info[data-block-track*="CookieNotice"]',
      ".ModuleTemplateCookieIndicator",
      ".o--cookies--container",
      "#cookies-policy-sticky",
      "#stickyCookieBar"
    ],
    icelandicAbp: [e("QVtocmVmXj0iL2ZyYW1ld29yay9yZXNvdXJjZXMvZm9ybXMvYWRzLmFzcHgiXQ==")],
    latvian: [
      e("YVtocmVmPSJodHRwOi8vd3d3LnNhbGlkemluaS5sdi8iXVtzdHlsZT0iZGlzcGxheTogYmxvY2s7IHdpZHRoOiAxMjBweDsgaGVpZ2h0OiA0MHB4OyBvdmVyZmxvdzogaGlkZGVuOyBwb3NpdGlvbjogcmVsYXRpdmU7Il0="),
      e("YVtocmVmPSJodHRwOi8vd3d3LnNhbGlkemluaS5sdi8iXVtzdHlsZT0iZGlzcGxheTogYmxvY2s7IHdpZHRoOiA4OHB4OyBoZWlnaHQ6IDMxcHg7IG92ZXJmbG93OiBoaWRkZW47IHBvc2l0aW9uOiByZWxhdGl2ZTsiXQ==")
    ],
    listKr: [
      e("YVtocmVmKj0iLy9hZC5wbGFuYnBsdXMuY28ua3IvIl0="),
      e("I2xpdmVyZUFkV3JhcHBlcg=="),
      e("YVtocmVmKj0iLy9hZHYuaW1hZHJlcC5jby5rci8iXQ=="),
      e("aW5zLmZhc3R2aWV3LWFk"),
      ".revenue_unit_item.dable"
    ],
    listeAr: [
      e("LmdlbWluaUxCMUFk"),
      ".right-and-left-sponsers",
      e("YVtocmVmKj0iLmFmbGFtLmluZm8iXQ=="),
      e("YVtocmVmKj0iYm9vcmFxLm9yZyJd"),
      e("YVtocmVmKj0iZHViaXp6bGUuY29tL2FyLz91dG1fc291cmNlPSJd")
    ],
    listeFr: [
      e("YVtocmVmXj0iaHR0cDovL3Byb21vLnZhZG9yLmNvbS8iXQ=="),
      e("I2FkY29udGFpbmVyX3JlY2hlcmNoZQ=="),
      e("YVtocmVmKj0id2Vib3JhbWEuZnIvZmNnaS1iaW4vIl0="),
      ".site-pub-interstitiel",
      'div[id^="crt-"][data-criteo-id]'
    ],
    officialPolish: [
      "#ceneo-placeholder-ceneo-12",
      e("W2hyZWZePSJodHRwczovL2FmZi5zZW5kaHViLnBsLyJd"),
      e("YVtocmVmXj0iaHR0cDovL2Fkdm1hbmFnZXIudGVjaGZ1bi5wbC9yZWRpcmVjdC8iXQ=="),
      e("YVtocmVmXj0iaHR0cDovL3d3dy50cml6ZXIucGwvP3V0bV9zb3VyY2UiXQ=="),
      e("ZGl2I3NrYXBpZWNfYWQ=")
    ],
    ro: [
      e("YVtocmVmXj0iLy9hZmZ0cmsuYWx0ZXgucm8vQ291bnRlci9DbGljayJd"),
      e("YVtocmVmXj0iaHR0cHM6Ly9ibGFja2ZyaWRheXNhbGVzLnJvL3Ryay9zaG9wLyJd"),
      e("YVtocmVmXj0iaHR0cHM6Ly9ldmVudC4ycGVyZm9ybWFudC5jb20vZXZlbnRzL2NsaWNrIl0="),
      e("YVtocmVmXj0iaHR0cHM6Ly9sLnByb2ZpdHNoYXJlLnJvLyJd"),
      'a[href^="/url/"]'
    ],
    ruAd: [
      e("YVtocmVmKj0iLy9mZWJyYXJlLnJ1LyJd"),
      e("YVtocmVmKj0iLy91dGltZy5ydS8iXQ=="),
      e("YVtocmVmKj0iOi8vY2hpa2lkaWtpLnJ1Il0="),
      "#pgeldiz",
      ".yandex-rtb-block"
    ],
    thaiAds: [
      "a[href*=macau-uta-popup]",
      e("I2Fkcy1nb29nbGUtbWlkZGxlX3JlY3RhbmdsZS1ncm91cA=="),
      e("LmFkczMwMHM="),
      ".bumq",
      ".img-kosana"
    ],
    webAnnoyancesUltralist: [
      "#mod-social-share-2",
      "#social-tools",
      e("LmN0cGwtZnVsbGJhbm5lcg=="),
      ".zergnet-recommend",
      ".yt.btn-link.btn-md.btn"
    ]
  };
}
function vt(e) {
  var n = e === void 0 ? {} : e, t = n.debug;
  return M(this, void 0, void 0, function() {
    var r, o, i, a, c, s;
    return C(this, function(l) {
      switch (l.label) {
        case 0:
          return mt() ? (r = dt(), o = Object.keys(r), i = (s = []).concat.apply(s, o.map(function(f) {
            return r[f];
          })), [4, ht(i)]) : [2, void 0];
        case 1:
          return a = l.sent(), t && pt(r, a), c = o.filter(function(f) {
            var u = r[f], h = R(u.map(function(p) {
              return a[p];
            }));
            return h > u.length * 0.6;
          }), c.sort(), [2, c];
      }
    });
  });
}
function mt() {
  return z() || De();
}
function ht(e) {
  var n;
  return M(this, void 0, void 0, function() {
    var t, r, o, i, s, a, c, s;
    return C(this, function(l) {
      switch (l.label) {
        case 0:
          for (t = document, r = t.createElement("div"), o = new Array(e.length), i = {}, ke(r), s = 0; s < e.length; ++s)
            a = pn(e[s]), a.tagName === "DIALOG" && a.show(), c = t.createElement("div"), ke(c), c.appendChild(a), r.appendChild(c), o[s] = a;
          l.label = 1;
        case 1:
          return t.body ? [3, 3] : [4, O(50)];
        case 2:
          return l.sent(), [3, 1];
        case 3:
          return t.body.appendChild(r), [4, te()];
        case 4:
          l.sent();
          try {
            for (s = 0; s < e.length; ++s)
              o[s].offsetParent || (i[e[s]] = !0);
          } finally {
            (n = r.parentNode) === null || n === void 0 || n.removeChild(r);
          }
          return [2, i];
      }
    });
  });
}
function ke(e) {
  e.style.setProperty("visibility", "hidden", "important"), e.style.setProperty("display", "block", "important");
}
function pt(e, n) {
  for (var t = "DOM blockers debug:\n```", r = 0, o = Object.keys(e); r < o.length; r++) {
    var i = o[r];
    t += `
`.concat(i, ":");
    for (var a = 0, c = e[i]; a < c.length; a++) {
      var s = c[a];
      t += `
  `.concat(n[s] ? "\u{1F6AB}" : "\u27A1\uFE0F", " ").concat(s);
    }
  }
  console.log("".concat(t, "\n```"));
}
function gt() {
  for (var e = 0, n = ["rec2020", "p3", "srgb"]; e < n.length; e++) {
    var t = n[e];
    if (matchMedia("(color-gamut: ".concat(t, ")")).matches)
      return t;
  }
}
function bt() {
  if (Le("inverted"))
    return !0;
  if (Le("none"))
    return !1;
}
function Le(e) {
  return matchMedia("(inverted-colors: ".concat(e, ")")).matches;
}
function yt() {
  if (Ie("active"))
    return !0;
  if (Ie("none"))
    return !1;
}
function Ie(e) {
  return matchMedia("(forced-colors: ".concat(e, ")")).matches;
}
var wt = 100;
function St() {
  if (!!matchMedia("(min-monochrome: 0)").matches) {
    for (var e = 0; e <= wt; ++e)
      if (matchMedia("(max-monochrome: ".concat(e, ")")).matches)
        return e;
    throw new Error("Too high value");
  }
}
function kt() {
  if (Q("no-preference"))
    return 0;
  if (Q("high") || Q("more"))
    return 1;
  if (Q("low") || Q("less"))
    return -1;
  if (Q("forced"))
    return 10;
}
function Q(e) {
  return matchMedia("(prefers-contrast: ".concat(e, ")")).matches;
}
function Lt() {
  if (xe("reduce"))
    return !0;
  if (xe("no-preference"))
    return !1;
}
function xe(e) {
  return matchMedia("(prefers-reduced-motion: ".concat(e, ")")).matches;
}
function It() {
  if (Pe("reduce"))
    return !0;
  if (Pe("no-preference"))
    return !1;
}
function Pe(e) {
  return matchMedia("(prefers-reduced-transparency: ".concat(e, ")")).matches;
}
function xt() {
  if (Ve("high"))
    return !0;
  if (Ve("standard"))
    return !1;
}
function Ve(e) {
  return matchMedia("(dynamic-range: ".concat(e, ")")).matches;
}
var w = Math, W = function() {
  return 0;
};
function Pt() {
  var e = w.acos || W, n = w.acosh || W, t = w.asin || W, r = w.asinh || W, o = w.atanh || W, i = w.atan || W, a = w.sin || W, c = w.sinh || W, s = w.cos || W, l = w.cosh || W, f = w.tan || W, u = w.tanh || W, h = w.exp || W, p = w.expm1 || W, k = w.log1p || W, P = function(b) {
    return w.pow(w.PI, b);
  }, V = function(b) {
    return w.log(b + w.sqrt(b * b - 1));
  }, S = function(b) {
    return w.log(b + w.sqrt(b * b + 1));
  }, y = function(b) {
    return w.log((1 + b) / (1 - b)) / 2;
  }, E = function(b) {
    return w.exp(b) - 1 / w.exp(b) / 2;
  }, G = function(b) {
    return (w.exp(b) + 1 / w.exp(b)) / 2;
  }, j = function(b) {
    return w.exp(b) - 1;
  }, N = function(b) {
    return (w.exp(2 * b) - 1) / (w.exp(2 * b) + 1);
  }, A = function(b) {
    return w.log(1 + b);
  };
  return {
    acos: e(0.12312423423423424),
    acosh: n(1e308),
    acoshPf: V(1e154),
    asin: t(0.12312423423423424),
    asinh: r(1),
    asinhPf: S(1),
    atanh: o(0.5),
    atanhPf: y(0.5),
    atan: i(0.5),
    sin: a(-1e300),
    sinh: c(1),
    sinhPf: E(1),
    cos: s(10.000000000123),
    cosh: l(1),
    coshPf: G(1),
    tan: f(-1e300),
    tanh: u(1),
    tanhPf: N(1),
    exp: h(1),
    expm1: p(1),
    expm1Pf: j(1),
    log1p: k(10),
    log1pPf: A(10),
    powPI: P(-100)
  };
}
var Vt = "mmMwWLliI0fiflO&1", ue = {
  default: [],
  apple: [{ font: "-apple-system-body" }],
  serif: [{ fontFamily: "serif" }],
  sans: [{ fontFamily: "sans-serif" }],
  mono: [{ fontFamily: "monospace" }],
  min: [{ fontSize: "1px" }],
  system: [{ fontFamily: "system-ui" }]
};
function Et() {
  return At(function(e, n) {
    for (var t = {}, r = {}, o = 0, i = Object.keys(ue); o < i.length; o++) {
      var a = i[o], c = ue[a], s = c[0], l = s === void 0 ? {} : s, f = c[1], u = f === void 0 ? Vt : f, h = e.createElement("span");
      h.textContent = u, h.style.whiteSpace = "nowrap";
      for (var p = 0, k = Object.keys(l); p < k.length; p++) {
        var P = k[p], V = l[P];
        V !== void 0 && (h.style[P] = V);
      }
      t[a] = h, n.append(e.createElement("br"), h);
    }
    for (var S = 0, y = Object.keys(ue); S < y.length; S++) {
      var a = y[S];
      r[a] = t[a].getBoundingClientRect().width;
    }
    return r;
  });
}
function At(e, n) {
  return n === void 0 && (n = 4e3), Ze(function(t, r) {
    var o = r.document, i = o.body, a = i.style;
    a.width = "".concat(n, "px"), a.webkitTextSizeAdjust = a.textSizeAdjust = "none", he() ? i.style.zoom = "".concat(1 / r.devicePixelRatio) : z() && (i.style.zoom = "reset");
    var c = o.createElement("div");
    return c.textContent = Ae([], Array(n / 20 << 0), !0).map(function() {
      return "word";
    }).join(" "), i.appendChild(c), e(o, i);
  }, '<!doctype html><html><head><meta name="viewport" content="width=device-width, initial-scale=1">');
}
function Mt() {
  return navigator.pdfViewerEnabled;
}
function Ct() {
  var e = new Float32Array(1), n = new Uint8Array(e.buffer);
  return e[0] = 1 / 0, e[0] = e[0] - e[0], n[3];
}
function Ft() {
  var e = window.ApplePaySession;
  if (typeof e?.canMakePayments != "function")
    return -1;
  try {
    return e.canMakePayments() ? 1 : 0;
  } catch (n) {
    return Wt(n);
  }
}
function Wt(e) {
  if (e instanceof Error) {
    if (e.name === "InvalidAccessError") {
      if (/\bfrom\b.*\binsecure\b/i.test(e.message))
        return -2;
      if (/\bdifferent\b.*\borigin\b.*top.level\b.*\bframe\b/i.test(e.message))
        return -3;
    }
    if (e.name === "SecurityError" && /\bthird.party iframes?.*\bnot.allowed\b/i.test(e.message))
      return -3;
  }
  throw e;
}
function Rt() {
  var e, n = document.createElement("a"), t = (e = n.attributionSourceId) !== null && e !== void 0 ? e : n.attributionsourceid;
  return t === void 0 ? void 0 : String(t);
}
var Te = -1, je = -2, Dt = /* @__PURE__ */ new Set([
  10752,
  2849,
  2884,
  2885,
  2886,
  2928,
  2929,
  2930,
  2931,
  2932,
  2960,
  2961,
  2962,
  2963,
  2964,
  2965,
  2966,
  2967,
  2968,
  2978,
  3024,
  3042,
  3088,
  3089,
  3106,
  3107,
  32773,
  32777,
  32777,
  32823,
  32824,
  32936,
  32937,
  32938,
  32939,
  32968,
  32969,
  32970,
  32971,
  3317,
  33170,
  3333,
  3379,
  3386,
  33901,
  33902,
  34016,
  34024,
  34076,
  3408,
  3410,
  3411,
  3412,
  3413,
  3414,
  3415,
  34467,
  34816,
  34817,
  34818,
  34819,
  34877,
  34921,
  34930,
  35660,
  35661,
  35724,
  35738,
  35739,
  36003,
  36004,
  36005,
  36347,
  36348,
  36349,
  37440,
  37441,
  37443,
  7936,
  7937,
  7938
]), Zt = /* @__PURE__ */ new Set([
  34047,
  35723,
  36063,
  34852,
  34853,
  34854,
  34229,
  36392,
  36795,
  38449
]), Gt = ["FRAGMENT_SHADER", "VERTEX_SHADER"], Tt = ["LOW_FLOAT", "MEDIUM_FLOAT", "HIGH_FLOAT", "LOW_INT", "MEDIUM_INT", "HIGH_INT"], Ne = "WEBGL_debug_renderer_info";
function jt(e) {
  var n, t, r, o, i, a, c = e.cache, s = Ye(c);
  if (!s)
    return Te;
  if (!He(s))
    return je;
  var l = Xe() ? null : s.getExtension(Ne);
  return {
    version: ((n = s.getParameter(s.VERSION)) === null || n === void 0 ? void 0 : n.toString()) || "",
    vendor: ((t = s.getParameter(s.VENDOR)) === null || t === void 0 ? void 0 : t.toString()) || "",
    vendorUnmasked: l ? (r = s.getParameter(l.UNMASKED_VENDOR_WEBGL)) === null || r === void 0 ? void 0 : r.toString() : "",
    renderer: ((o = s.getParameter(s.RENDERER)) === null || o === void 0 ? void 0 : o.toString()) || "",
    rendererUnmasked: l ? (i = s.getParameter(l.UNMASKED_RENDERER_WEBGL)) === null || i === void 0 ? void 0 : i.toString() : "",
    shadingLanguageVersion: ((a = s.getParameter(s.SHADING_LANGUAGE_VERSION)) === null || a === void 0 ? void 0 : a.toString()) || ""
  };
}
function Nt(e) {
  var n = e.cache, t = Ye(n);
  if (!t)
    return Te;
  if (!He(t))
    return je;
  var r = t.getSupportedExtensions(), o = t.getContextAttributes(), i = [], a = [], c = [], s = [];
  if (o)
    for (var l = 0, f = Object.keys(o); l < f.length; l++) {
      var u = f[l];
      i.push("".concat(u, "=").concat(o[u]));
    }
  for (var h = Ee(t), p = 0, k = h; p < k.length; p++) {
    var P = k[p], V = t[P];
    a.push("".concat(P, "=").concat(V).concat(Dt.has(V) ? "=".concat(t.getParameter(V)) : ""));
  }
  if (r)
    for (var S = 0, y = r; S < y.length; S++) {
      var E = y[S];
      if (!(E === Ne && Xe())) {
        var G = t.getExtension(E);
        if (!!G)
          for (var j = 0, N = Ee(G); j < N.length; j++) {
            var P = N[j], V = G[P];
            c.push("".concat(P, "=").concat(V).concat(Zt.has(V) ? "=".concat(t.getParameter(V)) : ""));
          }
      }
    }
  for (var A = 0, b = Gt; A < b.length; A++)
    for (var J = b[A], F = 0, v = Tt; F < v.length; F++) {
      var m = v[F], d = Yt(t, J, m);
      s.push("".concat(J, ".").concat(m, "=").concat(d.join(",")));
    }
  return c.sort(), a.sort(), {
    contextAttributes: i,
    parameters: a,
    shaderPrecisions: s,
    extensions: r,
    extensionParameters: c
  };
}
function Ye(e) {
  if (e.webgl)
    return e.webgl.context;
  var n = document.createElement("canvas"), t;
  n.addEventListener("webglCreateContextError", function() {
    return t = void 0;
  });
  for (var r = 0, o = ["webgl", "experimental-webgl"]; r < o.length; r++) {
    var i = o[r];
    try {
      t = n.getContext(i);
    } catch {
    }
    if (t)
      break;
  }
  return e.webgl = { context: t }, t;
}
function Yt(e, n, t) {
  var r = e.getShaderPrecisionFormat(e[n], e[t]);
  return r ? [r.rangeMin, r.rangeMax, r.precision] : [];
}
function Ee(e) {
  var n = Object.keys(e.__proto__);
  return n.filter(Xt);
}
function Xt(e) {
  return typeof e == "string" && !e.match(/[^A-Z0-9_x]/);
}
function Xe() {
  return Re();
}
function He(e) {
  return typeof e.getParameter == "function";
}
var Ht = {
  fonts: Cn,
  domBlockers: vt,
  fontPreferences: Et,
  audio: Sn,
  screenFrame: $n,
  canvas: Wn,
  osCpu: Hn,
  languages: Bn,
  colorDepth: zn,
  deviceMemory: Jn,
  screenResolution: On,
  hardwareConcurrency: et,
  timezone: nt,
  sessionStorage: rt,
  localStorage: it,
  indexedDB: ot,
  openDatabase: at,
  cpuClass: ct,
  platform: st,
  plugins: Fn,
  touchSupport: Xn,
  vendor: ut,
  vendorFlavors: lt,
  cookiesEnabled: ft,
  colorGamut: gt,
  invertedColors: bt,
  forcedColors: yt,
  monochrome: St,
  contrast: kt,
  reducedMotion: Lt,
  reducedTransparency: It,
  hdr: xt,
  math: Pt,
  pdfViewerEnabled: Mt,
  architecture: Ct,
  applePay: Ft,
  privateClickMeasurement: Rt,
  webGlBasics: jt,
  webGlExtensions: Nt
};
function Bt(e) {
  return un(Ht, e, []);
}
var zt = "$ if upgrade to Pro: https://fpjs.dev/pro";
function Jt(e) {
  var n = Ot(e), t = Ut(n);
  return { score: n, comment: zt.replace(/\$/g, "".concat(t)) };
}
function Ot(e) {
  if (De())
    return 0.4;
  if (z())
    return pe() && !(oe() && ie()) ? 0.5 : 0.3;
  var n = "value" in e.platform ? e.platform.value : "";
  return /^Win/.test(n) ? 0.6 : /^Mac/.test(n) ? 0.5 : 0.7;
}
function Ut(e) {
  return Fe(0.99 + 0.01 * e, 1e-4);
}
function Kt(e) {
  for (var n = "", t = 0, r = Object.keys(e).sort(); t < r.length; t++) {
    var o = r[t], i = e[o], a = "error" in i ? "error" : JSON.stringify(i.value);
    n += "".concat(n ? "|" : "").concat(o.replace(/([:|\\])/g, "\\$1"), ":").concat(a);
  }
  return n;
}
function Be(e) {
  return JSON.stringify(e, function(n, t) {
    return t instanceof Error ? on(t) : t;
  }, 2);
}
function ze(e) {
  return rn(Kt(e));
}
function Qt(e) {
  var n, t = Jt(e);
  return {
    get visitorId() {
      return n === void 0 && (n = ze(this.components)), n;
    },
    set visitorId(r) {
      n = r;
    },
    confidence: t,
    components: e,
    version: Me
  };
}
function _t(e) {
  return e === void 0 && (e = 50), Ue(e, e * 2);
}
function qt(e, n) {
  var t = Date.now();
  return {
    get: function(r) {
      return M(this, void 0, void 0, function() {
        var o, i, a;
        return C(this, function(c) {
          switch (c.label) {
            case 0:
              return o = Date.now(), [4, e()];
            case 1:
              return i = c.sent(), a = Qt(i), (n || r?.debug) && console.log("Copy the text below to get the debug data:\n\n```\nversion: ".concat(a.version, `
userAgent: `).concat(navigator.userAgent, `
timeBetweenLoadAndGet: `).concat(o - t, `
visitorId: `).concat(a.visitorId, `
components: `).concat(Be(i), "\n```")), [2, a];
          }
        });
      });
    }
  };
}
function $t() {
  if (!(window.__fpjs_d_m || Math.random() >= 1e-3))
    try {
      var e = new XMLHttpRequest();
      e.open("get", "https://m1.openfpcdn.io/fingerprintjs/v".concat(Me, "/npm-monitoring"), !0), e.send();
    } catch (n) {
      console.error(n);
    }
}
function er(e) {
  var n;
  return e === void 0 && (e = {}), M(this, void 0, void 0, function() {
    var t, r, o;
    return C(this, function(i) {
      switch (i.label) {
        case 0:
          return (!((n = e.monitoring) !== null && n !== void 0) || n) && $t(), t = e.delayFallback, r = e.debug, [4, _t(t)];
        case 1:
          return i.sent(), o = Bt({ cache: {}, debug: r }), [2, qt(o, r)];
      }
    });
  });
}
var nr = { load: er, hashComponents: ze, componentsToDebugString: Be };
const tr = "wingsSdk", _ = "items";
let le = "";
const rr = (e, n, t = {}) => {
  switch (e) {
    case "subscribe":
      return r();
    case "update":
      return o();
    case "unsubscribe":
      return i();
    case "subscribeDevice":
      return a();
    case "updateDevice":
      return c();
    case "unsubscribeDevice":
      return s();
    case "getSubscription":
      return l();
    case "getSubscriptions":
      return f();
    case "getContent":
      return u();
    case "getHistory":
      return h();
    case "selfCheck":
      return p();
    case "getSourceAddress":
      return V();
    case "getDeviceId":
      return S();
    default:
      throw Error(`Method ${e} not found`);
  }
  async function r() {
    if (!("publicKey" in n) || !n.publicKey)
      throw Error("publicKey not defined");
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    if (!("login" in n) || !n.login || !("password" in n) || !n.password)
      throw Error("login or password not defined");
    const { getSubscription: v } = ae({ publicKey: n.publicKey }), m = await v(), d = await P(n.clientId, n?.deviceId), g = "clientUserDefinedProperties" in n ? n.clientUserDefinedProperties : void 0, L = "deviceUserDefinedProperties" in n ? n.deviceUserDefinedProperties : void 0, I = await j(), Y = await G(I?.publicKey);
    return await J("keys", I), await J("address", d), await J("authString", n.login + ":" + n.password), await y(
      "/webpush/v1/client?" + new URLSearchParams({ sourceAddress: d }).toString(),
      "POST",
      { client: { userDefinedProperties: g }, device: { subscription: m, userDefinedProperties: L }, pubKey: Y },
      t
    );
  }
  async function o() {
    if (!("publicKey" in n) || !n.publicKey)
      throw Error("publicKey not defined");
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const v = await k(n.clientId), d = { client: { userDefinedProperties: "clientUserDefinedProperties" in n ? n.clientUserDefinedProperties : void 0 } }, g = await F("keys");
    if (!g)
      throw Error("Keys not found. Subscribe first.");
    const L = await A({ sourceAddress: v, ...d }, g?.value?.privateKey);
    return await y(
      "/webpush/v1/client?" + new URLSearchParams({ sourceAddress: v, signature: L }).toString(),
      "PUT",
      d,
      t
    );
  }
  async function i() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const v = await k(n.clientId), m = await F("keys");
    if (!m)
      throw Error("Keys not found. Subscribe first.");
    const d = await A({ sourceAddress: v }, m?.value?.privateKey);
    return await y(
      "/webpush/v1/client?" + new URLSearchParams({ sourceAddress: v, signature: d }).toString(),
      "DELETE",
      {},
      t
    );
  }
  async function a() {
    if (!("publicKey" in n) || !n.publicKey)
      throw Error("publicKey not defined");
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const { getSubscription: v } = ae({ publicKey: n.publicKey }), m = await v(), d = await k(n.clientId), g = "deviceUserDefinedProperties" in n ? n.deviceUserDefinedProperties : void 0, L = await j(), I = await G(L?.publicKey);
    return await J("keys", L), await y(
      "/webpush/v1/client/device?" + new URLSearchParams({ sourceAddress: d }).toString(),
      "POST",
      { device: { subscription: m, userDefinedProperties: g }, pubKey: I },
      t
    );
  }
  async function c() {
    if (!("publicKey" in n) || !n.publicKey)
      throw Error("publicKey not defined");
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const { getSubscription: v } = ae({ publicKey: n.publicKey }), m = (await v())?.toJSON(), d = await k(n.clientId), g = n?.destinationAddress, L = "deviceUserDefinedProperties" in n ? n.deviceUserDefinedProperties : void 0, I = { device: { subscription: m, userDefinedProperties: L } };
    let Y = { sourceAddress: d };
    g && (Y.destinationAddress = g);
    const H = await F("keys");
    if (!H)
      throw Error("Keys not found. Subscribe first.");
    const Je = await A({ ...Y, ...I }, H?.value?.privateKey);
    return await y(
      "/webpush/v1/client/device?" + new URLSearchParams({ ...Y, signature: Je }).toString(),
      "PUT",
      I,
      t
    );
  }
  async function s() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const v = await k(n.clientId), m = n?.destinationAddress, d = m ? { sourceAddress: v, destinationAddress: m } : { sourceAddress: v }, g = await F("keys");
    if (!g)
      throw Error("Keys not found. Subscribe first.");
    const L = await A({ sourceAddress: v }, g?.value?.privateKey);
    return await y(
      "/webpush/v1/client/device",
      "DELETE",
      { ...d, signature: L },
      t
    );
  }
  async function l() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const v = await k(n.clientId), m = { sourceAddress: v, destinationAddress: v }, d = await F("keys");
    if (!d)
      throw Error("Keys not found. Subscribe first.");
    const g = await A(m, d?.value?.privateKey);
    return await y(
      "/webpush/v1/client/device",
      "GET",
      { ...m, signature: g },
      t
    );
  }
  async function f() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    const m = { sourceAddress: await k(n.clientId), destinationAddress: n.clientId }, d = await F("keys");
    if (!d)
      throw Error("Keys not found. Subscribe first.");
    const g = await A(m, d?.value?.privateKey);
    return await y(
      "/webpush/v1/client/device",
      "GET",
      { ...m, signature: g },
      t
    );
  }
  async function u() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    if (!("pushMessageId" in n) || !n.pushMessageId)
      throw Error("pushMessageId not defined");
    const v = await k(n.clientId), m = n.pushMessageId, d = { sourceAddress: v, pushMessageId: m }, g = await F("keys");
    if (!g)
      throw Error("Keys not found. Subscribe first.");
    const L = await A(d, g?.value?.privateKey);
    return await y(
      "/webpush/v1/content/download",
      "GET",
      { ...d, signature: L },
      t
    );
  }
  async function h() {
    if (!("clientId" in n) || !n.clientId)
      throw Error("clientId not defined");
    let v = {};
    "channels" in n && n?.channels?.length > 0 && (v = { ...n, channels: n?.channels?.join(",") });
    let d = { sourceAddress: await k(n.clientId), ...v };
    delete d.clientId;
    const g = await F("keys");
    if (!g)
      throw Error("Keys not found. Subscribe first.");
    const L = await A(d, g?.value?.privateKey);
    return await y(
      "/webpush/v1/messages/get/history",
      "GET",
      { ...d, signature: L },
      t
    );
  }
  async function p() {
    const v = await f();
    return console.log("selfCheck res", v), !!v;
  }
  async function k(v, m = "") {
    const d = await F("address");
    return d && d?.value ? d.value : (m || (m = await S()), m + "." + v);
  }
  async function P(v, m) {
    return m || (m = await S()), m + "." + v;
  }
  async function V() {
    return (await F("address"))?.value;
  }
  async function S() {
    return le || (le = (await (await nr.load()).get()).visitorId), le;
  }
  async function y(v = "", m = "GET", d = {}, g = {}) {
    ["GET", "DELETE"].includes(m) && (v += "?" + new URLSearchParams(d).toString());
    const L = (await F("authString"))?.value;
    g.Authorization = "Basic " + btoa(L), g["Content-Type"] = "application/json";
    const I = await fetch(v, {
      method: m,
      headers: g,
      body: ["GET", "DELETE"].includes(m) ? null : JSON.stringify(d)
    });
    if (!I.ok)
      throw await I.json();
    return I.status === 204 ? I : await I.json();
  }
  function E(v) {
    return String.fromCharCode.apply(null, new Uint8Array(v));
  }
  async function G(v) {
    const m = await window.crypto.subtle.exportKey("spki", v);
    return window.btoa(E(m));
  }
  async function j() {
    return await window.crypto.subtle.generateKey(
      {
        name: "RSASSA-PKCS1-v1_5",
        modulusLength: 2048,
        publicExponent: new Uint8Array([1, 0, 1]),
        hash: "SHA-256"
      },
      !0,
      ["sign", "verify"]
    );
  }
  function N(v) {
    function m(L, I) {
      for (const [Y, H] of Object.entries(L))
        H != null && typeof H == "object" ? m(H, I) : I[Y] = H;
    }
    let d = {};
    m(v, d);
    let g = "";
    return "sourceAddress" in d && (g += d.sourceAddress, delete d.sourceAddress), "destinationAddress" in d && (g += d.destinationAddress, delete d.destinationAddress), "pushMessageId" in d && (g += d.pushMessageId, delete d.pushMessageId), Object.keys(d).sort().reduce((L, I) => (g += I + "" + d[I], L), {}), console.log("String to sign", v, g), g;
  }
  async function A(v, m) {
    typeof v == "object" && (v = N(v));
    const d = await window.crypto.subtle.sign(
      {
        name: "RSASSA-PKCS1-v1_5",
        saltLength: 32
      },
      m,
      new TextEncoder().encode(v)
    );
    return window.btoa(E(d));
  }
  function b(v) {
    let m = window.indexedDB.open(tr, 1);
    m.onerror = function(d) {
      console.log(d);
    }, m.onsuccess = function() {
      v(m.result);
    }, m.onupgradeneeded = function(d) {
      d?.target?.result?.createObjectStore(_, { keyPath: "key" }), b(v);
    };
  }
  function J(v, m) {
    return new Promise((d, g) => {
      b(function(L) {
        var I = L.transaction([_], "readwrite").objectStore(_).put({ key: v, value: m });
        I.onerror = function(Y) {
          g(Y);
        }, I.onsuccess = function() {
          d(I.result);
        };
      });
    });
  }
  async function F(v) {
    return new Promise((m, d) => {
      b(function(g) {
        var L = g.transaction([_], "readonly").objectStore(_).get(v);
        L.onerror = function(I) {
          d(I);
        }, L.onsuccess = function() {
          m(L.result);
        };
      });
    });
  }
};
export {
  rr as wingsSdk
};
