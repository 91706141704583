import React, {useCallback, useEffect, useMemo, useState} from 'react';
import './App.css';
import toast, {Toaster} from 'react-hot-toast';
import TextInput from "./components/Input";
import {wingsSdk} from './wings.es'
import { Device, Subscription, userDefinedProperty } from './types/types';

const publicKey = 'BAC1x6jWFG_tkgj6gX0d3JhGtZrm8EDvGm-UTBJiNnsFUNVPdXV_lEm2Ay3kJsgFIpBMhymRTZ7yzlyX8zDx37Y';

function App() {
    const [loadingSubscribe, setLoadingSubscribe] = useState<boolean>(false)
    const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
    const [loadingGetSubscription, setLoadingGetSubscription] = useState<boolean>(false)
    const [loadingGetSubscriptions, setLoadingGetSubscriptions] = useState<boolean>(false)
    const [subscriptions, setSubscriptions] = useState<Subscription>()
    const [selectedSubscription, setSelectedSubscription] = useState<Device>()
    const [clientUserDefinedProperties, setClientUserDefinedProperties] = useState<userDefinedProperty>({})
    const [deviceUserDefinedProperties, setDeviceUserDefinedProperties] = useState<userDefinedProperty>({})
    const [loadingUpdateSubscription, setLoadingUpdateSubscription] = useState<boolean>(false)
    const [loadingUnsubscribe, setLoadingUnsubscribe] = useState<boolean>(false)
    const [loadingUnsubscribeDevice, setLoadingUnsubscribeDevice] = useState<boolean>(false)
    const [pushMessageId, setPushMessageId] = useState<string>('')
    const [pushContent, setPushContent] = useState<object>()
    const [loadingGetContent, setLoadingGetContent] = useState<boolean>(false)
    const [loadingGetHistory, setLoadingGetHistory] = useState<boolean>(false)
    const [history, setHistory] = useState<object>()
    const [sourceAddress, setSourceAddress] = useState<string>('')
    const [clientId, setClientId] = useState<string>('')
    const [deviceId, setDeviceId] = useState<string>('')
    const [login, setLogin] = useState<string>('WebPushLogin')
    const [password, setPassword] = useState<string>('WebPushPassword')
    const [key, setKey] = useState<string>('')
    const [value, setValue] = useState<string>('')
    const [showTab, setShowTab] = useState<string>('client')

    const onShowClient = () => {
        setShowTab('client')
    }
    const onShowDevice = () => {
        setShowTab('device')
    }
    const onShowContent = () => {
        setShowTab('content')
    }

    const getAddress = (deviceId: string, clientId: string) => {
        return deviceId + '.' + clientId;
    }

    const onSubscribe = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingSubscribe(true)

        localStorage.setItem('clientId', clientId);

        try {
            const res = await wingsSdk('subscribe', {publicKey, clientId, deviceId, login, password})
            setIsSubscribed(true)

            const sourceAddress = await wingsSdk('getSourceAddress')
            if (sourceAddress) setSourceAddress(sourceAddress)

        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingSubscribe(false)
        }
    }, [publicKey, clientId, deviceId]);

    const getSubscription = async (clientId: string) => {
        const subscription: Subscription = await wingsSdk('getSubscription', {clientId})
        setSubscriptions(subscription)
        setSelectedSubscription(subscription?.device)
        const clientUserDefinedProperties = subscription?.client?.userDefinedProperties && subscription?.client?.userDefinedProperties instanceof Object ? subscription?.client?.userDefinedProperties : {}
        const deviceUserDefinedProperties = subscription?.device?.userDefinedProperties && subscription?.device?.userDefinedProperties instanceof Object ? subscription?.device?.userDefinedProperties : {}
        setClientUserDefinedProperties(clientUserDefinedProperties)
        setDeviceUserDefinedProperties(deviceUserDefinedProperties)
        return subscription
    }

    const onGetSubscription = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingGetSubscription(true)

        try {
            await getSubscription(clientId)

        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingGetSubscription(false)
        }
    }, [clientId]);

    const onGetSubscriptions = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingGetSubscriptions(true)

        try {
            const subscription: Subscription = await wingsSdk('getSubscriptions', {clientId})
            setSubscriptions(subscription)
            setSelectedSubscription(undefined)
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingGetSubscriptions(false)
        }
    }, [clientId]);

    const onUpdateSubscription = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingUpdateSubscription(true)

        try {
            const res = await wingsSdk('update', {publicKey, clientId, clientUserDefinedProperties})
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingUpdateSubscription(false)
        }

    }, [publicKey, clientId, selectedSubscription, clientUserDefinedProperties]);

    const onUpdateDeviceSubscription = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectedSubscription) return
        setLoadingUpdateSubscription(true)

        try {
            const res = await wingsSdk('updateDevice', {publicKey, clientId, destinationAddress: getAddress(selectedSubscription?.id, clientId), deviceUserDefinedProperties})
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingUpdateSubscription(false)
        }

    }, [publicKey, clientId, selectedSubscription, deviceUserDefinedProperties]);

    const onUnsubscribe = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingUnsubscribe(true)

        try {
            await wingsSdk('unsubscribe', {clientId})
            setIsSubscribed(false)
            // localStorage.setItem('clientId', '');
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingUnsubscribe(false)
        }
    }, [clientId]);

    const onUnsubscribeDevice = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();

        if (!selectedSubscription) return
        setLoadingUnsubscribeDevice(true)
        console.log('selectedSubscription', selectedSubscription, getAddress(selectedSubscription?.id, clientId))

        try {
            await wingsSdk('unsubscribeDevice', {clientId, destinationAddress: getAddress(selectedSubscription?.id, clientId)})
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingUnsubscribeDevice(false)
        }
    }, [clientId, selectedSubscription]);

    const onGetContent = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingGetContent(true)

        try {
            const pushContent = await wingsSdk('getContent', {clientId, pushMessageId})
            setPushContent(pushContent)
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingGetContent(false)
        }
    }, [clientId, pushMessageId]);

    const onGetHistory = useCallback(async (e: React.FormEvent) => {
        e.preventDefault();
        setLoadingGetHistory(true)

        const payload = {
            clientId,
            destinationAddress: sourceAddress,
            channels: ['Push', 'SMS', 'Viber', 'WhatsApp', 'MailNotify'],
            groupByExternalId: false,
            minId: 'WCS###PUSH_MESSAGE_ID1',
            maxId: 'WCS###PUSH_MESSAGE_ID3',
            minDate: '2022-12-31T22:01:01+03:00',
            maxDate: '2024-12-31T22:02:01+03:00',
            count: 20,
            messagesStatuses: 'Delivered',
        }

        try {
            const history = await wingsSdk('getHistory', payload)
            setHistory(history)
        } catch(error) {
            console.log('error', error)
        } finally {
            setLoadingGetHistory(false)
        }
    }, [clientId, sourceAddress]);

    const onSelectSubscription = (e: any) => {
        if (subscriptions?.device instanceof Array) {
            const subscription = subscriptions?.device?.find(item => item.id == e.target.value)
            setSelectedSubscription(subscription)
            setDeviceUserDefinedProperties(subscription?.userDefinedProperties ? subscription.userDefinedProperties : null)
        }
    }

    const onChange = useCallback((setState: React.Dispatch<React.SetStateAction<string>>) => (e: React.ChangeEvent<HTMLInputElement>) => {
        console.log(e)
        setState(e.target.value);
    }, []);

    const onAddClientUserDefinedProps = () => {
        if (!clientUserDefinedProperties || !key || !value) return
        clientUserDefinedProperties[key]  = value
        setClientUserDefinedProperties({...clientUserDefinedProperties})
        setKey('')
        setValue('')
    }

    const onDeleteClientUserDefinedProperties = (key: string) => {
        if (!clientUserDefinedProperties) return
        if (key in clientUserDefinedProperties) delete clientUserDefinedProperties[key]
        setClientUserDefinedProperties({...clientUserDefinedProperties})
    }

    const onAddDeviceUserDefinedProps = () => {
        if (!deviceUserDefinedProperties || !key || !value) return
        deviceUserDefinedProperties[key] = value
        setDeviceUserDefinedProperties({...deviceUserDefinedProperties})
        setKey('')
        setValue('')
    }

    const onDeleteDeviceUserDefinedProperties = (key: string) => {
        if (!deviceUserDefinedProperties) return
        if (key in deviceUserDefinedProperties) delete deviceUserDefinedProperties[key]
        setDeviceUserDefinedProperties({...deviceUserDefinedProperties})
    }

    useEffect(() => {
        const clientId = localStorage.getItem('clientId');
        if (clientId) {
            setClientId(clientId);
        }
        const loadPost = async () => {
            try {
                const sourceAddress = await wingsSdk('getSourceAddress')
                if (sourceAddress) setSourceAddress(sourceAddress)
                if (clientId) {
                    const sub = await getSubscription(clientId)
                    setIsSubscribed(true)
                }
            } catch(error) {
                console.log(error)
            }
        };
        loadPost();
    }, []);


    return (
        <div className="App">
            <main>
                <div>
                    <div className="message">
                        <div className="title">Использовать в качестве PWA</div>
                        <div>
                            Вам необходимо установить сайт на свой домашний экран.
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="title">SourceAddress</div>
                    <div className='subscription'>{ sourceAddress }</div>
                </div>
                {!isSubscribed &&
                <>
                    <div className="row">
                        <label htmlFor="clientId" className='title mr-1'>Client id</label>
                        <input className='mr-1' id="clientId" placeholder="Номер телефона" value={clientId} onChange={onChange(setClientId)} required type="number"/>
                    </div>
                    <div className="row">
                        <label htmlFor="deviceId" className='title mr-1'>Device id</label>
                        <input className='mr-1' id="deviceId" placeholder="ID устройства" value={deviceId} onChange={onChange(setDeviceId)}/>
                    </div>
                    <div className="row">
                        <label htmlFor="login" className='title mr-1'>Login</label>
                        <input className='mr-1' id="login" placeholder="Логин" value={login} onChange={onChange(setLogin)} required/>
                    </div>
                    <div className="row">
                        <label htmlFor="password" className='title mr-1'>Password</label>
                        <input className='mr-1' id="password" placeholder="Пароль" value={password} onChange={onChange(setPassword)}/>
                    </div>
                    <div className="row">
                        <button onClick={onSubscribe} className={loadingSubscribe ? 'loading' : ''} disabled={!clientId}  type="submit">Подписаться</button>
                    </div>
                </>
                }
                {isSubscribed &&
                    <>
                    <div className="tabs">
                        <div className={`tab-item`}>
                            <button className={`tab ${showTab == 'client' ? 'active' : ''}`} onClick={onShowClient}>Клиент</button>
                        </div>
                        <div className={`tab-item`}>
                            <button className={`tab ${showTab == 'device' ? 'active' : ''}`} onClick={onShowDevice}>Устройство</button>
                        </div>
                        <div className={`tab-item`}>
                            <button className={`tab ${showTab == 'content' ? 'active' : ''}`} onClick={onShowContent}>Контент</button>
                        </div>
                    </div>

                    {showTab == 'client' &&
                        <>
                        <div className="row">
                            <div className='title'>userDefinedProperties</div>
                            <div className="mb-1">
                                {clientUserDefinedProperties && Object.keys(clientUserDefinedProperties).map((key, index) =>
                                    <div key={index}>
                                        <span className='mr-1'>{key}: {clientUserDefinedProperties[key]}</span>
                                        <span onClick={() => onDeleteClientUserDefinedProperties(key)} className='danger pointer' title='Удалить'>🗑</span>
                                    </div>)}
                            </div>
                            <div className='mb-1'>
                                <input className='mr-1' id={key} placeholder="Key" value={key} onChange={onChange(setKey)} required type="text"/>
                                <input className="mr-1" id={value} placeholder="Value" value={value} onChange={onChange(setValue)} required type="text"/>
                                <button onClick={onAddClientUserDefinedProps} type="submit">Добавить</button>
                            </div>

                            {clientUserDefinedProperties &&
                                <div>
                                    <button onClick={onUpdateSubscription} className={loadingUpdateSubscription ? 'loading' : ''} type="submit">Обновить подписку</button>
                                </div>
                            }
                        </div>

                        <div className="row">
                            <button onClick={onUnsubscribe} className={loadingUnsubscribe ? 'loading danger' : 'danger'}  type="submit">Отписаться</button>
                        </div>
                        </>
                    }
                    {showTab == 'device' &&
                        <div className="row">
                            <button onClick={onGetSubscription} className={loadingGetSubscription ? 'loading mr-1' : 'mr-1'} type="submit">Получить текущую подписку</button>
                            <button onClick={onGetSubscriptions} className={loadingGetSubscriptions ? 'loading' : ''} type="submit">Получить все подписки</button>

                            {subscriptions?.device instanceof Array && subscriptions?.device.length > 0 &&
                                <div className='mt-1 mb-1'>
                                    <label htmlFor="subs" className='title'>Подписки</label><br/>
                                    <select name="" id="subs" onChange={onSelectSubscription}>
                                        <option value=""></option>
                                        {subscriptions?.device.map(({id}, index) => <option value={id} key={index}>{id}</option>)}
                                    </select>
                                </div>
                            }
                            {selectedSubscription &&
                                <div>
                                    {subscriptions?.device instanceof Object && <div className='mt-1 title'>Подписка</div>}
                                    <div className='mt-1 mb-1' dangerouslySetInnerHTML={{ __html: JSON.stringify(selectedSubscription) }}/>
                                    <div className='title'>userDefinedProperties</div>
                                    <div className="mb-1">
                                        {deviceUserDefinedProperties && Object.keys(deviceUserDefinedProperties).map((key, index) =>
                                            <div key={index}>
                                                <span className='mr-1'>{key}: {deviceUserDefinedProperties[key]}</span>
                                                <span onClick={() => onDeleteDeviceUserDefinedProperties(key)} className='danger pointer' title='Удалить'>🗑</span>
                                            </div>)}
                                    </div>
                                    <div className='mb-1'>
                                        <input className='mr-1' id={key} placeholder="Key" value={key} onChange={onChange(setKey)} required type="text"/>
                                        <input className="mr-1" id={value} placeholder="Value" value={value} onChange={onChange(setValue)} required type="text"/>
                                        <button onClick={onAddDeviceUserDefinedProps} type="submit">Добавить</button>
                                    </div>

                                    {deviceUserDefinedProperties &&
                                        <div>
                                            <button onClick={onUpdateDeviceSubscription} className={loadingUpdateSubscription ? 'loading' : ''} type="submit">Обновить подписку</button>
                                        </div>
                                    }
                                    <div className="row">
                                        <button onClick={onUnsubscribeDevice} className={loadingUnsubscribeDevice ? 'loading danger' : 'danger'}  type="submit">Отписаться</button>
                                    </div>
                                </div>
                            }
                        </div>
                    }
                    {showTab == 'content' &&
                        <>
                        <div className="row">
                            <label htmlFor="push-message-id" className='title mr-1'>pushMessageId</label>
                            {/* <div className="title">push-message-id</div> */}
                            {/* <TextInput id="push-message-id" placeholder="push-message-id" value={pushMessageId} onChange={onChange(setPushMessageId)}/> */}
                            <input className='mr-1' id="push-message-id" placeholder="pushMessageId" value={pushMessageId} onChange={onChange(setPushMessageId)} required type="text"/>
                            <button onClick={onGetContent} className={loadingGetContent ? 'loading' : ''} type="submit">Получить контент</button>
                            <div className='mt-1' dangerouslySetInnerHTML={{ __html: JSON.stringify(pushContent) }}/>
                        </div>
                        <div className="row">
                            <button onClick={onGetHistory} className={loadingGetHistory ? 'loading' : ''}  type="submit">Получить историю</button>
                            <div className='mt-1' dangerouslySetInnerHTML={{ __html: JSON.stringify(history) }}/>
                        </div>
                        </>
                    }
                    </>
                }
            </main>
            <Toaster/>
        </div>
    );
}

export default App;
